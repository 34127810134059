import { useEffect, useState } from "react"
import { createContainer } from "unstated-next"


const DEFAULT_CONFIG = {
  drawingsFeedPageLength: 33,
  commentsFeed: {
    limit: 10
  }
}

export const UserPreferences = () => {
  const [config, setConfig] = useState({})
  const [loading, setLoading] = useState(true)

  const cacheConfig = () => {
    if (config) {
      localStorage.setItem('nzk-tutoring-config', JSON.stringify(config))
    }
  }

  // Load cached
  useEffect(() => {
    const config = localStorage.getItem('nzk-tutoring-config')
    if (config && true) {
      setConfig(JSON.parse(config))
    } else {
      setConfig(DEFAULT_CONFIG)
    }
    setLoading(false)
  }, [])

  // Save config
  useEffect(() => {
    cacheConfig()
    // eslint-disable-next-line
  }, [config])

  const updateConfig = (payload) => {
    setConfig(config => ({
      ...config,
      ...payload
    }))
  }

  return { config, updateConfig, loading }
}

const UserPreferencesContainer = createContainer(UserPreferences)

export const UserPreferencesProvider = UserPreferencesContainer.Provider
export const useUserPreferences = () => UserPreferencesContainer.useContainer()
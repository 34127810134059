import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, StudentTab } from './index.styles'
import { navigate } from 'gatsby'
import useTabs from '../../../Context/TabsContext/hooks/useTabs'
import { FormClose } from 'grommet-icons'
import Avatar from '../../../UI/Avatar'

const Tab = ({ id, user, link,  }) => {
  const { removeTab } = useTabs()

  const onRemove = (e) => {
    e.preventDefault()
    e.stopPropagation()
    removeTab(id)
  }

  return <Wrapper onClick={() => navigate(link)}>
    { user && <StudentTab>
      <Avatar user={user} size='20px' />
      <div>{user.username || user.name}</div>
    </StudentTab> }
    <FormClose onClick={onRemove}/>
  </Wrapper>
}

Tab.propTypes = {
  id: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  user: PropTypes.shape({
    avatar: PropTypes.shape({
      url: PropTypes.string
    }),
    username: PropTypes.string
  })
}

Tab.defaultProps = {
}

export default Tab

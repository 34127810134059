import React from 'react'
import PropTypes from 'prop-types'
import { reducer, initialState, ACTIONS } from './context'

let UserContext = React.createContext()

function UserContextProvider (props) {
  let [state, dispatch] = React.useReducer(reducer, { ...initialState, ...props.state })
  let value = { state, dispatch }

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  )
}

UserContextProvider.propTypes = {
  children: PropTypes.node,
  state: PropTypes.object
}

let UserContextConsumer = UserContext.Consumer

export { ACTIONS, UserContext, UserContextProvider, UserContextConsumer }

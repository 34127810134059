import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const DEFAULT_AVATAR = `https://cdn.nightzookeeper.com/edu-assets/images/empty-avatar.png`

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  background-color: #ebebeb;
  background-size: ${props => props.backgroundSize || 'cover'};
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
  width: ${props => props.size};
  height: ${props => props.size};
`

const Flag = styled.div`
  position: absolute;
  top: -6%;
  right: -6%;
  height: 40%;
  width: 40%;
  border-radius: 50%;
  background-position: center;
  background-image: url("${props => `https://cdn.nightzookeeper.com/edu-assets/images/flags/${props.countryCode}.png`}");
  background-size: cover;
`

const sizeMap = {
  small: '25px',
  regular: '30px',
  large: '40px'
}

const getAvatar = ({ src, user }) => {
  if (src) {
    return src
  }

  if (user && user.avatar && user.avatar.url) {
    return user.avatar.url
  }

  if (user && user.type === 'student') {
    return DEFAULT_AVATAR
  }

  return DEFAULT_AVATAR
}

const Avatar = (props) => {
  const size = sizeMap[props.size] || props.size || '30px'
  const avatar = getAvatar(props)

  return <Wrapper
    {...props}
    src={avatar}
    size={size}
    backgroundSize={props.backgroundSize || 'cover'}
  >
    { props.user && props.user.countryCode && <Flag countryCode={props.user.countryCode} /> }
  </Wrapper>
}

Avatar.propTypes = {
  src: PropTypes.string, // you can pass in a src directly
  user: PropTypes.object, // or a user object, and we work out what the image should be
  size: PropTypes.string,
  backgroundSize: PropTypes.string
}

export default Avatar

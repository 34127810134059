import React from 'react'
import PropTypes from 'prop-types'
import { reducer, initialState, ACTIONS } from './context'

let TabsContext = React.createContext()

function TabsContextProvider (props) {
  let [state, dispatch] = React.useReducer(reducer, { ...initialState, ...props.state })
  if (!state) {
    state = { ...initialState, ...props.state }
    dispatch = () => {}
  }

  let value = { state, dispatch }
  return (
    <TabsContext.Provider value={value}>{props.children}</TabsContext.Provider>
  )
}

TabsContextProvider.propTypes = {
  children: PropTypes.node,
  state: PropTypes.object
}

let TabsContextConsumer = TabsContext.Consumer

export { ACTIONS, TabsContext, TabsContextProvider, TabsContextConsumer }

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '../../theme';

const Wrapper = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  transform: translate(-50%, -50%);
  > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${props => props.size - props.thickness / 2}px;
    height: ${props => props.size - props.thickness / 2}px;
    margin: 3px;
    border: ${props => props.thickness}px solid ${props => props.color};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => props.color} transparent transparent transparent;
  }
  > div:nth-child(1) {
    animation-delay: -0.45s;
  }
    > div:nth-child(2) {
    animation-delay: -0.3s;
  }
  > div:nth-child(3) {
    animation-delay: -0.15s;
  }
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

`

const Loader = ({ color, size, thickness }) => {
  return <Wrapper color={color} size={size} thickness={thickness}><div></div><div></div><div></div><div></div></Wrapper>
}

Loader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  thickness: PropTypes.number
}

Loader.defaultProps = {
  color: colors.indigo,
  size: 64,
  thickness: 6
}

export default Loader

import React, { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import qs from 'querystring'
import Axios from 'axios'
import config from '../config'

const AuthenticationState = () => {
  const [currentUser, setCurrentUser] = useState(null)
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    onTokenChanged(token)
  }, [token])

  const init = () => {
    setLoading(true)
    const sharedSecretKey = localStorage.getItem('lscache-v2-shared-secret-key')
    const userId = localStorage.getItem('lscache-v2-user-id')
    const token = localStorage.getItem('access-token')
    if (token) {
      onSetToken(JSON.parse(token))
    } else if (sharedSecretKey && userId && !token) {
      // getToken(userId, sharedSecretKey)
    }
    setLoading(false)
  }

  const onTokenExpired = () => {
    localStorage.removeItem('access-token')
  }

  const refreshToken = async ({ refreshToken }) => {
    // if (!refreshToken?.value) return null
    // const { data } = await Axios.post('https://oauth.nightzookeeper.com/oauth/token', qs.stringify({
    //   refresh_token: refreshToken.value,
    //   client_id: config.oauthClientId,
    //   client_secret: '', // ! Not wise
    //   grant_type: 'refresh_token'
    // }))
    // console.log(data)
  }

  const onSetToken = (token) => {
    // Check token validity
    let newToken
    if (new Date(token.refreshToken.expiresAt) < new Date()) {
      onTokenExpired()
    } else if (new Date(token.accessToken.expiresAt) < new Date()) {
      refreshToken(token)
    } else {
      newToken = token
    }
    setToken(newToken)
  }

  // const handleRawToken = (rawToken) => {
  //   try {
  //     let decoded = decode(rawToken)
  //     onSetToken({
  //       accessToken: {
  //         value: rawToken,
  //         expiresAt: new Date(decoded.exp)
  //     }})
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  const getToken = async (email, password) => {
    let data
    try {
      let { data: result } = await Axios.post('https://oauth.nightzookeeper.com/oauth/token', qs.stringify({
        username: email,
        password: password,
        client_id: config.oauthClientId,
        grant_type: 'password'
      }))
      data = result
    } catch (err) {
      throw new Error('Account not found.')
    }

    if (data.user.type !== 'tutor') throw new Error('Not a tutoring account.')
    setCurrentUser(data.user)
    onSetToken({
      accessToken: {
        value: data.accessToken,
        expiresAt: data.accessTokenExpiresAt
      },
      refreshToken: {
        value: data.refreshToken,
        expiresAt: data.refreshTokenExpiresAt
      }
    })
  }

  const onTokenChanged = (token) => {
    if (!token) return
    localStorage.setItem('access-token', JSON.stringify(token))
  }

  const logout = () => {
    localStorage.removeItem('access-token')
    setToken()
    setCurrentUser()
  }

  return {
    currentUser,
    loading,
    isLoggedIn: token && true,
    token,
    setToken: onSetToken,
    setCurrentUser,
    getToken,
    logout
  }
}

const AuthenticationContainer = createContainer(AuthenticationState)

export const AuthenticationProvider = (props) => 
  <AuthenticationContainer.Provider initialState={props}>
    { props.children }
  </AuthenticationContainer.Provider>

export const useAuthenticationState = () => {
  return AuthenticationContainer.useContainer()
}
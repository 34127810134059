export const colors = {
  lightGray: '#E3E8EE',
  indigo: '#556CD6',
  darkPurple: '#191F36',
  green: '#1FA672',
  red: '#E56F4A',
  darkBlue: '#3C4257',
  gray: '#6F798B',
  lighterGray: '#F8FAFC'
}

export const theme = {
  global: {
    font: {
      family: 'Roboto',
      size: '14px',
      height: '20px',
    },
    colors: {
      brand: '#E3E8EE',
      brandText: '#1A1F36',
      text: {
        light: colors.darkPurple
      }
    },
    table: {
      header: {
        background: colors.lighterGray,
        border: 'bottom'
      },
      body: {
        border: 'bottom'
      }
    },
    checkBox: {
      color: colors.darkPurple,
      border: { color: 'red' }
    }
  },
  checkBox: {
    color: colors.darkPurple
  }
};

export default theme

import { cache } from "../../data/apolloClient"

export let initialState = {
  user: {
    _id: typeof window !== 'undefined' && localStorage.getItem('nzk-userId'),
    sharedSecretKey: typeof window !== 'undefined' && localStorage.getItem('nzk-sharedSecretKey')
  },
  loggedIn: typeof window !== 'undefined' && localStorage.getItem('nzk-userId') && localStorage.getItem('nzk-sharedSecretKey') && true
}

export const ACTIONS = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT'
}

export let reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOGIN: {
      if (typeof window !== 'undefined') {
        localStorage.setItem('nzk-userId', action.payload._id)
        localStorage.setItem('nzk-sharedSecretKey', action.payload.sharedSecretKey)
      }
      return { ...state, user: action.payload, loggedIn: true }
    }
    case ACTIONS.LOGOUT: {
      if (typeof window !== 'undefined') {
        localStorage.removeItem('nzk-userId')
        localStorage.removeItem('nzk-sharedSecretKey')
        cache.persistor.purge()
      }
      return { ...state, user: null, loggedIn: false }
    }
    default: {
      return state
    }
  }
}

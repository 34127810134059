import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  right: 25px;
  display: flex;
  > * {
    margin-right: 10px;
  }
  > :last-child {
    margin-right: 0;
  }
`
import React, { Component } from 'react'
import ModalContext from '../Context/ModalContext'

export default class ModalManager extends Component {
  static propTypes = {}

  constructor (props) {
    super(props)

    this.state = {
      modalStack: []
    }

    this.modalMethods = {
      open: (view, id) => this.addModalToBack({ view, id }),
      shyOpen: (view, id) => this.addModalToFront({ view, id }),
      forceOpen: (view, id) =>
        this.addModalAndClear({ view, id }),
      close: (id) => this.removeModal(id),
      closeMany: (ids) => this.removeModal(ids),
      closeAll: () => this.clearStack(),
      updateProps: (newProps, id) => this.updateProps(newProps, id),
      getCurrent: () => this.getCurrentModal(),
      getAll: () => this.getAllModals()
    }

    this.addModalToFront = this.addModalToFront.bind(this)
    this.addModalToBack = this.addModalToBack.bind(this)
    this.addModalAndClear = this.addModalAndClear.bind(this)
    this.removeModal = this.removeModal.bind(this)
    this.clearStack = this.clearStack.bind(this)
    this.updateProps = this.updateProps.bind(this)
  }

  componentDidUpdate () {
    if (this.state.modalStack.length) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'scroll'
    }
  }

  getCurrentModal () {
    return this.state.modalStack[this.state.modalStack.length - 1] || {}
  }

  getAllModals () {
    return this.state.modalStack || []
  }

  addModalToFront (modal) {
    this.setState({
      modalStack: [modal, ...this.state.modalStack]
    })
  }

  addModalToBack (modal) {
    this.setState({
      modalStack: [...this.state.modalStack, modal]
    })
  }

  addModalAndClear (modal) {
    this.setState({
      modalStack: [modal]
    })
  }

  removeModal (id) {
    if (id) {
      if (Array.isArray(id)) {
        return this.setState({
          modalStack: this.state.modalStack.filter(
            modal => !id.includes(modal.id)
          )
        })
      }
      return this.setState({
        modalStack: this.state.modalStack.filter(
          modal => modal.id !== id
        )
      })
    }
    this.setState({
      modalStack: [
        ...this.state.modalStack.slice(0, this.state.modalStack.length - 1)
      ]
    })
  }

  clearStack () {
    this.setState({
      modalStack: []
    })
  }

  updateProps (newProps, id) {
    this.setState({
      modalStack: this.state.modalStack.map((modal, i) => (
        (id ? modal.id === id : i === this.state.modalStack.length - 1)
          ? { ...modal, view: { ...modal.view, props: { ...modal.view.props, ...newProps } } }
          : modal
      ))
    })
  }

  render () {
    const modals = this.getAllModals()

    return (
      <ModalContext.Provider value={this.modalMethods}>
        <div>
          {modals.map(({ view }, i) => (
            <view.type
              key={i}
              isOpen
              onConfirm={() => this.removeModal()}
              onCancel={() => this.removeModal()}
              {...view.props}
            />
          ))}
          <ChildrenRenderer children={this.props.children} />
        </div>
      </ModalContext.Provider>
    )
  }
}

class ChildrenRenderer extends Component {
  shouldComponentUpdate () {
    return false
  }

  render () {
    return this.props.children
  }
}

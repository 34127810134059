import React from "react"
import Layout from "./layout"
import AppLayout from "./AppLayout"
import "./layout.css"
import { RouterProvider } from "../providers/RouterProvider"
import { AuthenticationProvider, useAuthenticationState } from "../providers/AuthenticationProvider"
import { navigate } from "gatsby"
import { colors } from "../theme"
import styled from "styled-components"
import Loader from "../components/UI/Loader"
import { GlobalStyles } from 'twin.macro'

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-color: ${colors.indigo};
`

const BaseLayout = (props) => {
  const {
    children,
    pageContext,
    uri
  } = props
  const { loading, isLoggedIn } = useAuthenticationState()

  if (!pageContext) return null
  if (!new RegExp('/login').test(uri) && !isLoggedIn && !loading) {
    navigate('/login')
    return null
  }

  if (pageContext.layout === "AppLayout") {
    return <AppLayout location={uri} {...props}>{children}</AppLayout>
  }
  return <Layout>{children}</Layout>
}

export default (props) => {
  return <>
    <RouterProvider {...props}>
      <AuthenticationProvider>
        <BaseLayout {...props} />
      </AuthenticationProvider>
    </RouterProvider>
  </>
}
import React from 'react'
import { createContainer } from 'unstated-next'

const RouterState = (initialState) => {
  const { location } = initialState
  const queryParams = new URLSearchParams(location.search)

  return {
    location,
    queryParams
  }
}

const RouterContainer = createContainer(RouterState)

export const RouterProvider = (props) => 
  <RouterContainer.Provider initialState={props}>
    { props.children }
  </RouterContainer.Provider>

export const useRouter = () => {
  return RouterContainer.useContainer()
}
import React from "react"
import { ApolloProvider } from "@apollo/client"
import { client } from "./src/data/apolloClient"
import { UserContextProvider } from "./src/context/user"
import { IntlProvider } from "react-intl"
import { StudentsContextProvider } from "./src/components/Context/StudentsContext"
// import Layout from "./src/layouts"
import { Grommet } from "grommet"
import theme from "./src/theme"
import ModalManager from "./src/components/ModalManager/ModalManager"
import { TabsContextProvider } from "./src/components/Context/TabsContext"
import Notifications from "./src/components/Notifications"
import { UserPreferencesProvider} from "./src/context/UserPreferences"
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// export const wrapPageElement = ({ element, props }) => {
//   // props provide same data to Layout as Page element will get
//   // including location, data, etc - you don't need to pass it
//   return <Layout {...props}>{element}</Layout>
// }


export const wrapRootElement = ({ element }) => (
  <>
    <IntlProvider locale="en-gb">
      <UserContextProvider>
        <ApolloProvider client={client}>
          <UserPreferencesProvider>
            <StudentsContextProvider>
              <Grommet theme={theme}>
                <TabsContextProvider>
                  <ModalManager>
                    <Notifications>
                      {element}
                    </Notifications>
                  </ModalManager>
                </TabsContextProvider>
              </Grommet>
            </StudentsContextProvider>
          </UserPreferencesProvider>
        </ApolloProvider>
      </UserContextProvider>
    </IntlProvider>
  </>
)

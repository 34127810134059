import { useContext, useEffect } from 'react'
import { TabsContext, ACTIONS } from '..'

const useTabs = () => {
  const { state, dispatch } = useContext(TabsContext)
  const { tabs } = state

  useEffect(() => {
    saveState()
    // eslint-disable-next-line
  }, [tabs])

  const setTabs = (newTabs) => {
    dispatch({ type: ACTIONS.SET_TABS, payload: newTabs })
  }

  const removeTab = (id) => {
    dispatch({ type: ACTIONS.REMOVE_TAB, payload: id })
  }

  const addTab = (payload) => {
    dispatch({ type: ACTIONS.ADD_TAB, payload })
  }

  const saveState = async () => {
    try {
      await localStorage.setItem('tabs', JSON.stringify(tabs))
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  return { tabs, removeTab, addTab, setTabs }
}

export default useTabs

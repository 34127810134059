export let initialState = {
  students: [],
  customers: []
}

export const ACTIONS = {
  SET_STUDENTS: 'SET_STUDENTS',
  ADD_STUDENTS: 'ADD_STUDENTS',
  SET_CUSTOMERS: 'SET_CUSTOMERS',
  ADD_CUSTOMERS: 'ADD_CUSTOMERS'
}

export let reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_STUDENTS: {
      return { ...state, students: [...action.payload] }
    }
    case ACTIONS.ADD_STUDENTS: {
      return { ...state, students: [...state.students, ...action.payload] }
    }
    case ACTIONS.SET_CUSTOMERS: {
      return { ...state, customers: [...action.payload] }
    }
    case ACTIONS.ADD_CUSTOMERS: {
      return { ...state, customers: [...state.customers, ...action.payload] }
    }
    default: {
      return state
    }
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { reducer, initialState, ACTIONS } from './context'

let StudentsContext = React.createContext()

function StudentsContextProvider (props) {
  let [state, dispatch] = React.useReducer(reducer, { ...initialState, ...props.state })
  if (!state) {
    state = { ...initialState, ...props.state }
    dispatch = () => {}
  }
  let value = { state, dispatch }

  return (
    <StudentsContext.Provider value={value}>{props.children}</StudentsContext.Provider>
  )
}

StudentsContextProvider.propTypes = {
  children: PropTypes.node,
  state: PropTypes.object
}

let StudentsContextConsumer = StudentsContext.Consumer

export { ACTIONS, StudentsContext, StudentsContextProvider, StudentsContextConsumer }

import { useEffect, useState } from "react"
import shortid from "shortid"
import { createContainer } from "unstated-next"
import { colors } from "../../theme"

const NotificationsState = () => {
  const [notifications, setNotifications] = useState([])

  const removeNotificationWithKey = (notifications, key) => {
    setNotifications([
      ...(notifications || [])
    ].filter(n => n.key !== key))
  }

  const addNotification = (payload) => {
    let key = shortid.generate()

    setTimeout(() =>
      setNotifications((notifications) => removeNotificationWithKey(notifications || [], key))
    , 2000)
    
    setNotifications([ { key, ...payload, remove: () => removeNotificationWithKey(notifications || [], key) }, ...(notifications || []) ])
  }

  const notifySuccess = (payload) => {
    addNotification({ ...payload, color: colors.green })
  }

  const notifyInfo = (payload) => {
    addNotification({ ...payload, color: colors.indigo })
  }

  const notifyError = (payload) => {
    addNotification({ ...payload, color: colors.red })
  }

  return {
    notifications,
    notifySuccess,
    notifyInfo,
    notifyError
  }
}

const NotificationStateContainer = createContainer(NotificationsState)

export const useNotificationState = NotificationStateContainer.useContainer

export default NotificationStateContainer

import { Link } from "gatsby"
import { Heading } from "grommet"
import { Avatar, Button } from 'nzk-react-components'
import React, { ReactElement } from "react"
import styled, { css } from "styled-components"
import { useAuthenticationState } from "../../providers/AuthenticationProvider"
import theme from "../../theme"

const Wrapper = styled.ul`
  list-style-type: none;
  padding: 30px 15px 0 15px;
  margin: 0;
  background-color: #fff;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  a {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  font-size: 14px;
  > div {
    margin-bottom: 20px;
  }
  > :first-child {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    > :first-child {
      height: 40px;
      margin: 0;
    }
    > :last-child {
      > * {
        margin: 0;
      }
      > :nth-child(1) {
        font-size: 13px;
        line-height: 20px;
      }
      > :nth-child(2) {
        font-size: 12px;
        line-height: 10px;
      }
    }
  }
`

const Information = styled.div`
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 8px;
  > :first-child {
    user-select: none;
    display: flex;
    align-items: center;
    line-height: 15px;
    font-size: 13px;
    > :first-child {
      min-width: 30px;
    }
    > :nth-child(2) {
      margin-left: 8px;
    }
  }
  > :nth-child(2) {
    font-size: 8px;
    text-align: center;
  }
`

const Item = styled.li<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  line-height: 20px;
  margin: 0;
  ${props => props.selected
    ? css`
      color: #556CD6;
      font-weight: bold;
    `
    : css`
      color: ${theme.global.colors.brandText};
  `}
  cursor: pointer;
  > a > :first-child {
    margin-right: 12px;
  }
`

const SectionTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`

const ICON_SIZE = "18px"

interface IProps {
  location: string
  unexpand: () => void
  data: {
    me: {
      name: string
      email: string
      avatar: {
        url: string
      }
      type: 'tutor'
    }
  },
  sections: {
    title?: string
    items: {
      icon: ReactElement
      name: string
      path: string
      match: RegExp
    }[]
  }[]
}

const SideNav = (props: IProps) => {
  const { location, unexpand, data, sections } = props
  const { logout } = useAuthenticationState()

  return (
      <Wrapper>
        { /* eslint-disable-next-line */ }
        <div onClick={unexpand}>
          <img src='/images/logo.svg' alt="Night Zookeeper logo" />
          <div>
            <Heading level={5}>Night Zookeeper</Heading>
            <Heading level={6}>Tutoring</Heading>
          </div>
        </div>
        {data && data.me && (
          <Information>
            <div>
            <Avatar user={data.me} size='30px' />
            <div>{data.me.name}</div>
          </div>
          <div>
            {data.me.email}
          </div>
          </Information>
        )}
        {
          sections.map(section => <div key={Math.random() * 100}>
            { section.title && <SectionTitle>{section.title}</SectionTitle> }
            { section.items.map((s, i) => <Item key={i} selected={Boolean(location.match(s.match))}>
            <Link to={s.path}>
              { React.cloneElement(s.icon, { size: ICON_SIZE, color:
                  location.match(s.match)
                    ? "#556CD6"
                    : theme.global.colors.brandText
                })}
              {" "}
              { s.name }
            </Link>
          </Item> )}
          </div>)
        }
        <div>
          <Button theme='red' size='small' onClick={logout}>Logout</Button>
        </div>
      </Wrapper>
  )
}

export default SideNav

import React from 'react'
import shortid from 'shortid'
import styled from 'styled-components'
import NotificationState from './index.state'

const NotificationsWrapper = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 250px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 10px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const Notification = styled.div`
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 6px 12px;
  > :first-child {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  > :nth-child(2) {
    font-size: 14px;
  }
  ::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    background-color: ${props => props.color};
    height: 4px;
    animation: animateBorder 2s linear;
  }
  @keyframes animateBorder {
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
  }
`

const Notifications = ({ children }) => {
  const { notifications } = NotificationState.useContainer()

  return <>
    { children }
    <NotificationsWrapper>
      {
        (notifications || []).map(notification =>
          <Notification key={shortid.generate()} color={notification.color} onClick={notification.remove}>
            <div>{notification.title}</div>
            <div>{notification.text}</div>
          </Notification>)
      }
    </NotificationsWrapper>
  </>
}

Notifications.propTypes = {

}

Notifications.defaultProps = {

}

export default (props) => <NotificationState.Provider>
  <Notifications {...props} />
</NotificationState.Provider>

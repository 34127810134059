import { defaultDataIdFromObject, InMemoryCache, ApolloClient, ApolloLink, from, HttpLink } from '@apollo/client';
import { persistCache, LocalForageWrapper } from 'apollo3-cache-persist';
import localForage from 'localforage';
// import { getHawkHeader } from '../lib/hawk'
import fetch from 'unfetch';
import config from '../config';

const httpLink = new HttpLink({
  uri: config.graphqlEndpoint,
  fetch
});

const authLink = new ApolloLink((operation, forward) => {
  let token
  if (typeof window !== 'undefined' && localStorage.getItem('access-token')) {
    token = JSON.parse(localStorage.getItem('access-token')).accessToken.value
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`
      }
    })
  }

  return forward(operation)
})

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation).map((data) => {
    return data;
  });
});

export const cache = new InMemoryCache({
  StarTutoringSubscription: {
    fields: {
    }
  },
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'UserCurriculumGroup': return null; // Prevents caching stats in common group
      default: return defaultDataIdFromObject(object);
    }
  }
})

persistCache({
  cache,
  storage: new LocalForageWrapper(localForage)
})

export const client = new ApolloClient({
  link: from([
    cleanTypeName,
    authLink,
    httpLink
  ]),
  cache
})

import styled from 'styled-components'
import { colors } from '../../theme';

const Button = styled.div`
  background-color: ${props => props.bgColor || colors.indigo};
  color: ${props => props.color || '#fff'};
  min-width: 100px;
  padding: 0 10px;
  padding: 0 8px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.1px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
  transform: scale(1);
  transition: transform 0.2s;
  :hover {
    transform: scale(1.1);
    transition: transform 0.2s;
  }
`

export const IconButton = styled(Button)`
  width: auto;
  min-width: 0;
  height: auto;
  padding: 10px;
  > * {
    margin-left: 8px;
  }
  > :first-child {
    margin-left: 0;
  }
`

export default Button

import React from 'react'
// import PropTypes from 'prop-types'
import { Wrapper } from './index.styles'
import useTabs from '../Context/TabsContext/hooks/useTabs'
import Tab from './components/Tab'
import { useTransition, animated } from 'react-spring'

const TabsLayout = (props) => {
  const { tabs } = useTabs()

  const transitions = useTransition(tabs, tab => tab.id, {
    from: { y: 45 },
    enter: { y: 0 },
    leave: { y: 45 }
  })
  return <Wrapper>
    {
      transitions.map(({ key, item, props}) => (
        <animated.div key={key} style={{
          transform: props.y.interpolate(y => `translateY(${y}px)`)
        }}>
          <Tab key={item.id} {...item } />
        </animated.div>
      ))
    }
  </Wrapper>
}

TabsLayout.propTypes = {

}

TabsLayout.defaultProps = {

}

export default TabsLayout

import { Link } from "gatsby"
import { Avatar, Logout } from 'nzk-react-components'
import PropTypes from "prop-types"
import React, { ReactElement } from "react"
import styled from "styled-components"
import tw from 'twin.macro'
import { useAuthenticationState } from "../../providers/AuthenticationProvider"
import theme from "../../theme"

const Wrapper = styled.ul`
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  width: 70px;
  height: 100%;
  max-height: 100vh;
  padding-top: 20px;
  overflow: visible;
  a {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  font-size: 14px;

`

const Content = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  > div {
    margin-bottom: 20px;
  }
  > :first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    > :first-child {
      height: 40px;
      margin: 0;
    }
  }

  overflow: auto;
  height: 100%;
  > * {
    width: 100%;
  }

  > :last-child {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
`

const Information = styled.div`
  padding: 8px;
  user-select: none;
  display: flex;
  justify-content: center;
`

const Item = styled.li<{ selected: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 20px;
  margin: 0;
  background-color: ${props =>
    props.selected ? "#556CD6" : 'inherit'};
  color: ${props =>
    props.selected ? "#fff" : theme.global.colors.brandText};
  cursor: pointer;
  a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    height: 100%;
    width: 100%;
  }
  :hover {
    background-color: #556cd666;
  }
`

const Tooltip = styled.div`
  position: absolute;
  ${tw`w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md text-white
    bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100`}
`

const ICON_SIZE = "26px"

interface IProps {
  location: string
  expand: () => void
  data: {
    me: {
      name: string
      email: string
      avatar: {
        url: string
      }
      type: 'tutor'
    }
  },
  sections: {
    title?: string
    items: {
      icon: ReactElement
      name: string
      path: string
      match: RegExp
    }[]
  }[]
}

const SideNav = (props: IProps) => {
  const { location, expand, data, sections } = props
  const { logout } = useAuthenticationState()

  return (
    <Wrapper>
      <Content>
        {/* eslint-disable-next-line */}
        <div onClick={expand}>
          <img
            src="/images/logo.svg"
            alt="Night Zookeeper logo"
          />
        </div>
        {data && data.me && (
          <Information>
            <Avatar user={data.me} size='30px' />
          </Information>
        )}
        {sections.map((section, i) => (
          <div key={`g-${i}`}>
            {section.items.map((s, i) => (
              <Item key={i} selected={new RegExp(s.match).test(location)} className='group'>
                <Link to={s.path}>
                  {React.cloneElement(s.icon, {
                    size: ICON_SIZE,
                    color: new RegExp(s.match).test(location)
                      ? "#FFFFFF"
                      : theme.global.colors.brandText,
                  })}{" "}
                </Link>
                <Tooltip>{s.name}</Tooltip>
              </Item>
            ))}
          </div>
        ))}
        {/* eslint-disable-next-line */}
        <div onClick={logout}>
          <Logout height='26px' width='26px' />
        </div>
      </Content>
    </Wrapper>
  )
}

SideNav.propTypes = {
  // eslint-disable-next-line
  location: PropTypes.string,
  // eslint-disable-next-line
  expand: PropTypes.func,
  // eslint-disable-next-line
  data: PropTypes.shape({
    // eslint-disable-next-line
    me: PropTypes.any
  }),
  // eslint-disable-next-line
  sections: PropTypes.array,
}

export default SideNav

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Box, Grid } from "grommet"
import styled from "styled-components"
import SideNav from "../components/SideNav"
import "./layout.css"
import TabsLayout from "../components/TabsLayout"
import TopNav from '../components/TopNav'
import Layout from './layout'

const ContentBox = styled(Box)`
  min-height: calc(100vh - 99px);
`

const Content = styled.div`
  width: 100%;
  flex: 1;
  overflow-x: hidden;
`

const AppLayout = props => {
  const { children, location } = props

  useEffect(() => {
    if (typeof window === "undefined") return
    window.addEventListener("beforeunload", clearFetchData)
    return function cleanUp() {
      window.removeEventListener("beforeunload", clearFetchData)
    }
  }, [])

  const clearFetchData = () => {
    if (typeof window === "undefined") return
    localStorage.removeItem("students-lastFetched")
    localStorage.removeItem("customers-lastFetched")
  }

  if (typeof window === "undefined") return null

  return (
    <Layout>
      <Box background="brand">
        <Grid
          areas={[
            { name: "nav", start: [0, 0], end: [0, 0] },
            { name: "main", start: [1, 0], end: [1, 0] },
          ]}
          columns={["auto", "flex"]}
          rows={["flex"]}
          gap="none"
        >
          <Box gridArea="nav" color="brandText" style={{ minWidth: '60px' }}>
            <SideNav location={location.pathname} />
          </Box>
          <Content>
            <TopNav />
            <Box pad="medium">
              <ContentBox gridArea="main">{children}</ContentBox>
            </Box>
          </Content>
        </Grid>
          <TabsLayout />
      </Box>
    </Layout>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
}

export default AppLayout

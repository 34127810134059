import styled from 'styled-components'
import { colors } from '../../../../theme'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  min-width: 180px;
  align-items: center;
  height: 40px;
  border-radius: 8px 8px 0px 0px;
  padding: 0 24px 0 10px;
  border-top: 4px solid ${colors.indigo};
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
  > :last-child {
    position: absolute;
    right: 0px;
  }

  :hover {
    background-color: #e9ebee;
  }
`

export const StudentTab = styled.div`
  display: flex;
  > :nth-child(2) {
    margin-left: 6px;
    font-weight: bold;
    font-size: 14px;
  }
`

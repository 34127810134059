exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animals-feed-js": () => import("./../../../src/pages/animals-feed.js" /* webpackChunkName: "component---src-pages-animals-feed-js" */),
  "component---src-pages-animals-js": () => import("./../../../src/pages/animals.js" /* webpackChunkName: "component---src-pages-animals-js" */),
  "component---src-pages-avatars-feed-js": () => import("./../../../src/pages/avatars-feed.js" /* webpackChunkName: "component---src-pages-avatars-feed-js" */),
  "component---src-pages-book-records-js": () => import("./../../../src/pages/book-records.js" /* webpackChunkName: "component---src-pages-book-records-js" */),
  "component---src-pages-comments-js": () => import("./../../../src/pages/comments.js" /* webpackChunkName: "component---src-pages-comments-js" */),
  "component---src-pages-create-lesson-js": () => import("./../../../src/pages/create-lesson.js" /* webpackChunkName: "component---src-pages-create-lesson-js" */),
  "component---src-pages-create-parent-js": () => import("./../../../src/pages/create-parent.js" /* webpackChunkName: "component---src-pages-create-parent-js" */),
  "component---src-pages-customer-[email]-tsx": () => import("./../../../src/pages/customer/[email].tsx" /* webpackChunkName: "component---src-pages-customer-[email]-tsx" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-drawings-js": () => import("./../../../src/pages/drawings.js" /* webpackChunkName: "component---src-pages-drawings-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lesson-lesson-id-tsx": () => import("./../../../src/pages/lesson/[lessonId].tsx" /* webpackChunkName: "component---src-pages-lesson-lesson-id-tsx" */),
  "component---src-pages-lesson-serie-serie-id-tsx": () => import("./../../../src/pages/lesson-serie/[serieId].tsx" /* webpackChunkName: "component---src-pages-lesson-serie-serie-id-tsx" */),
  "component---src-pages-lesson-series-js": () => import("./../../../src/pages/lesson-series.js" /* webpackChunkName: "component---src-pages-lesson-series-js" */),
  "component---src-pages-lesson-tags-js": () => import("./../../../src/pages/lesson-tags.js" /* webpackChunkName: "component---src-pages-lesson-tags-js" */),
  "component---src-pages-lessons-js": () => import("./../../../src/pages/lessons.js" /* webpackChunkName: "component---src-pages-lessons-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-order-order-id-tsx": () => import("./../../../src/pages/order/[orderId].tsx" /* webpackChunkName: "component---src-pages-order-order-id-tsx" */),
  "component---src-pages-orders-tsx": () => import("./../../../src/pages/orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-send-lesson-js": () => import("./../../../src/pages/send-lesson.js" /* webpackChunkName: "component---src-pages-send-lesson-js" */),
  "component---src-pages-send-message-js": () => import("./../../../src/pages/send-message.js" /* webpackChunkName: "component---src-pages-send-message-js" */),
  "component---src-pages-star-tutoring-assignment-templates-[id]-edit-tsx": () => import("./../../../src/pages/star-tutoring/assignment-templates/[id]/edit.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-assignment-templates-[id]-edit-tsx" */),
  "component---src-pages-star-tutoring-assignment-templates-[id]-index-tsx": () => import("./../../../src/pages/star-tutoring/assignment-templates/[id]/index.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-assignment-templates-[id]-index-tsx" */),
  "component---src-pages-star-tutoring-assignment-templates-create-tsx": () => import("./../../../src/pages/star-tutoring/assignment-templates/create.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-assignment-templates-create-tsx" */),
  "component---src-pages-star-tutoring-assignment-templates-index-tsx": () => import("./../../../src/pages/star-tutoring/assignment-templates/index.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-assignment-templates-index-tsx" */),
  "component---src-pages-star-tutoring-education-categories-[id]-edit-tsx": () => import("./../../../src/pages/star-tutoring/education-categories/[id]/edit.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-education-categories-[id]-edit-tsx" */),
  "component---src-pages-star-tutoring-education-categories-create-tsx": () => import("./../../../src/pages/star-tutoring/education-categories/create.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-education-categories-create-tsx" */),
  "component---src-pages-star-tutoring-education-categories-index-tsx": () => import("./../../../src/pages/star-tutoring/education-categories/index.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-education-categories-index-tsx" */),
  "component---src-pages-star-tutoring-index-tsx": () => import("./../../../src/pages/star-tutoring/index.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-index-tsx" */),
  "component---src-pages-star-tutoring-lineups-[id]-edit-tsx": () => import("./../../../src/pages/star-tutoring/lineups/[id]/edit.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-lineups-[id]-edit-tsx" */),
  "component---src-pages-star-tutoring-lineups-[id]-index-tsx": () => import("./../../../src/pages/star-tutoring/lineups/[id]/index.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-lineups-[id]-index-tsx" */),
  "component---src-pages-star-tutoring-lineups-create-tsx": () => import("./../../../src/pages/star-tutoring/lineups/create.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-lineups-create-tsx" */),
  "component---src-pages-star-tutoring-lineups-index-tsx": () => import("./../../../src/pages/star-tutoring/lineups/index.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-lineups-index-tsx" */),
  "component---src-pages-star-tutoring-preset-sentences-[id]-edit-tsx": () => import("./../../../src/pages/star-tutoring/preset-sentences/[id]/edit.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-preset-sentences-[id]-edit-tsx" */),
  "component---src-pages-star-tutoring-preset-sentences-create-tsx": () => import("./../../../src/pages/star-tutoring/preset-sentences/create.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-preset-sentences-create-tsx" */),
  "component---src-pages-star-tutoring-preset-sentences-index-tsx": () => import("./../../../src/pages/star-tutoring/preset-sentences/index.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-preset-sentences-index-tsx" */),
  "component---src-pages-star-tutoring-reviews-[id]-tsx": () => import("./../../../src/pages/star-tutoring/reviews/[id].tsx" /* webpackChunkName: "component---src-pages-star-tutoring-reviews-[id]-tsx" */),
  "component---src-pages-star-tutoring-reviews-index-tsx": () => import("./../../../src/pages/star-tutoring/reviews/index.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-reviews-index-tsx" */),
  "component---src-pages-star-tutoring-zookeepers-[username]-tsx": () => import("./../../../src/pages/star-tutoring/zookeepers/[username].tsx" /* webpackChunkName: "component---src-pages-star-tutoring-zookeepers-[username]-tsx" */),
  "component---src-pages-star-tutoring-zookeepers-index-tsx": () => import("./../../../src/pages/star-tutoring/zookeepers/index.tsx" /* webpackChunkName: "component---src-pages-star-tutoring-zookeepers-index-tsx" */),
  "component---src-pages-student-[username]-index-tsx": () => import("./../../../src/pages/student/[username]/index.tsx" /* webpackChunkName: "component---src-pages-student-[username]-index-tsx" */),
  "component---src-pages-task-challenges-js": () => import("./../../../src/pages/task-challenges.js" /* webpackChunkName: "component---src-pages-task-challenges-js" */),
  "component---src-pages-tutor-id-tsx": () => import("./../../../src/pages/tutor/[_id].tsx" /* webpackChunkName: "component---src-pages-tutor-id-tsx" */),
  "component---src-pages-tutors-js": () => import("./../../../src/pages/tutors.js" /* webpackChunkName: "component---src-pages-tutors-js" */),
  "component---src-pages-upload-js": () => import("./../../../src/pages/upload.js" /* webpackChunkName: "component---src-pages-upload-js" */),
  "component---src-pages-writing-writing-id-tsx": () => import("./../../../src/pages/writing/[writingId].tsx" /* webpackChunkName: "component---src-pages-writing-writing-id-tsx" */),
  "component---src-pages-writings-js": () => import("./../../../src/pages/writings.js" /* webpackChunkName: "component---src-pages-writings-js" */)
}


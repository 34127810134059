import { navigate } from "gatsby"
import { TextInput } from "grommet"
import { Next, Previous } from "grommet-icons"
import React, { useState } from "react"
import styled from "styled-components"
import { colors } from "../../theme"
import { IconButton } from "../UI/Button"

const Wrapper = styled.div`
  height: 50px;
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
`

const BackButton = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const GoTo = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 20px;
  width: 100%;
  flex: 1;
  margin-left: auto;
  max-width: 380px;
  > :first-child {
    min-width: 73px;
    font-weight: bold;
  }
  > * {
    margin-right: 8px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const Select = styled.select`
  border: none;
  padding: 11px;
  outline: none;
  background: transparent;
  color: inherit;
  border: 1px solid rgba(0, 0, 0, 0.33);
  border-radius: 4px;
  width: 100px;
  outline: none;
  height: 44px;
  background-color: white;
`

const StyledIconButton = styled(IconButton)`
  height: 44px;
  width: 44px;
`

const TopNav = props => {
  const [root, setRoot] = useState("/student/")
  const [value, setValue] = useState("")

  const onSubmit = () => {
    navigate(`${root}${value}`)
    setValue("")
  }

  return (
    <Wrapper>
      <BackButton onClick={() => window.history.back()}>
        <Previous />
      </BackButton>
      <GoTo>
        <div>Quick Find</div>
        <Select value={root} onChange={e => setRoot(e.target.value)}>
          <option value="/student/">Student</option>
          <option value="/customer/">Customer</option>
          <option value="/order/">Order</option>
        </Select>
        <TextInput
          value={value}
          placeholder={
            root === "/student/"
              ? "Username"
              : root === "/order/"
              ? "Order"
              : "Email"
          }
          onChange={e => setValue(e.target.value)}
          onKeyPress={e => e.which === 13 && onSubmit()}
        />
        <StyledIconButton bgColor={colors.indigo} onClick={onSubmit}>
          <Next color="#fff" />
        </StyledIconButton>
      </GoTo>
    </Wrapper>
  )
}

TopNav.propTypes = {}

TopNav.defaultProps = {}

export default TopNav

export let initialState = {
  tabs: typeof window !== 'undefined'
    ? localStorage.getItem('tabs') ? JSON.parse(localStorage.getItem('tabs')) : []
    : []
  }

export const ACTIONS = {
  SET_TABS: 'SET_TABS',
  ADD_TAB: 'ADD_TAB',
  REMOVE_TAB: 'REMOVE_TAB'
}

export let reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_TABS: {
      return { ...state, tabs: [...action.payload] }
    }
    case ACTIONS.ADD_TAB: {
      if (state.tabs.filter(tab => tab.id === action.payload.id).length > 0) return state
      return { ...state, tabs: [...state.tabs, action.payload] }
    }
    case ACTIONS.REMOVE_TAB: {
      return { ...state, tabs: [...state.tabs.filter(tab => tab.id !== action.payload)] }
    }
    case ACTIONS.ADD_CUSTOMERS: {
      return { ...state, customers: [...state.customers, ...action.payload] }
    }
    default: {
      return state
    }
  }
}

const ENV = process.env.GATSBY_CONFIG || "production"

const configs = {
  local: {
    env: "development",
    eduServer: "http://localhost:8080",
    graphqlEndpoint: "http://localhost:8080/graphql",
    graphqlSchemaVersion: 5, // Bumb when schema changes
    fileServer: "https://file-server.nightzookeeper.com/upload",
    oauthClientId: "ijosdjia0asdasd0asda09",
    apollo: {
      connectToDevTools: true,
    },
  },
  staging: {
    env: "development",
    eduServer: "https://gql-staging.nightzookeeper.com",
    graphqlEndpoint: "https://gql-staging.nightzookeeper.com/graphql",
    graphqlSchemaVersion: 5, // Bumb when schema changes
    fileServer: "https://file-server.nightzookeeper.com/upload",
    oauthClientId: "ijosdjia0asdasd0asda09",
    apollo: {
      connectToDevTools: true,
    },
  },
  production: {
    env: "development",
    eduServer: "https://gql.nightzookeeper.com",
    graphqlEndpoint: "https://gql.nightzookeeper.com/graphql",
    graphqlSchemaVersion: 5, // Bumb when schema changes
    fileServer: "https://file-server.nightzookeeper.com/upload",
    oauthClientId: "ijosdjia0asdasd0asda09",
    apollo: {
      connectToDevTools: true,
    },
  },
}

export default configs[ENV]
